// Dependency
import React, { useState } from "react";
import { graphql, Link } from "gatsby";

// Internationalization
import { useTranslation } from 'react-i18next';

// Components
import Layout from "../../components/layouts";
import Hero from "../../components/hero/hero";
import CenteredContent from "../../components/centered-content";

// Styles
import * as giftStyles from "./gitft-cards.module.scss"
import BackgroundCard from "../../components/cards/background-card";
import BackgroundCards from "../../components/background-cards";
import * as backgroundCardsStyles from "../../components/background-cards.module.scss";

// Assets

// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en" ) {
  datoCmsGiftCardPage (locale: {eq: $locale}) {
    title
    heroContent
    mobileHero{
      fluid{
        ...GatsbyDatoCmsFluid
      }
    }
    desktopHero{
      fluid(maxWidth: 1500){
        ...GatsbyDatoCmsFluid
      }
    }
    centeredContent
    backgroundCards{
      featuredMedia{
        fluid(maxWidth: 500){
          ...GatsbyDatoCmsFluid
        }
      }
      title
      subtitle
      link
      buttonText
      pdfLink
    }
  }
}
`

// Template
const GiftCard = (props) => {
    // Extract page data
    const pageData = props.data.datoCmsGiftCardPage;

    // Hero media
    const mediaData = {
        mobileImg: pageData && pageData.mobileHero ? pageData.mobileHero.fluid : '',
        desktopImg: pageData && pageData.desktopHero ? pageData.desktopHero.fluid : '',
    }
    const sources = [
        pageData.mobileHero.fluid,
        {
            ...pageData.desktopHero.fluid,
            media: `(min-width: 900px)`,
        },
    ]

    // Translation Function
    const { t } = useTranslation();

    return (
        <Layout seoData={pageData.seoMetaTags} country={props.pageContext.country} location={props.location}>
            <Hero
                sources={sources}
                mediaData={mediaData}
            >
                { // Hero Content is visible only if data exist
                    pageData && pageData.heroContent && <div
                        dangerouslySetInnerHTML={{ __html: pageData.heroContent }}>
                    </div>
                }
            </Hero>
            <CenteredContent>
                { // MediaBanner is visible only if data exist
                    pageData && pageData.centeredContent && <div
                        dangerouslySetInnerHTML={{ __html: pageData.centeredContent }}>
                    </div>
                }
            </CenteredContent>

            <div className={giftStyles.cdGiftCardsSection}>
                <div className="cd-max-width">
                    <div className={giftStyles.cdGiftCardsGrid}>
                        {pageData.backgroundCards.map((backgroundCard, index) => {
                            return (
                                <div key={index} className={`${backgroundCardsStyles.cdBackgroundCardGridCard} ${giftStyles.cdGiftCard}`}>
                                    <BackgroundCard data={backgroundCard} country={props.pageContext.country} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default GiftCard